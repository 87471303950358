import { richText, stripRichTextWrapperTag } from '../../utils/html';
import { adapter as imageAdapter } from '../../components/cloud-image/utils';
import getPrice from '../../utils/get-price';
import { getEkomiData, getRating } from '~/utils/ekomi';

export const getQuickAccessData = (heroData, $enhancedLinkSerializer, i18n) => {
  const quickAccess = {
    withQuickAccess: heroData.active_quick_access,
    quickAccessTitle: heroData.quick_access_title,
    quickAccessData: heroData.quick_access_links,
    quickAccessType: heroData.quick_access_type,
  };

  return quickAccess.withQuickAccess
    ? quickAccessAdapter(quickAccess, {
        $enhancedLinkSerializer,
        i18n,
      })
    : {};
};

const quickAccessAdapter = (
  { withQuickAccess, quickAccessTitle, quickAccessData, quickAccessType },
  { $enhancedLinkSerializer, i18n }
) => ({
  withQuickAccess,
  quickAccessType,
  quickAccessData: {
    title: quickAccessTitle,
    listProducts: quickAccessData
      .filter(
        ({ quick_access_link, quick_access_label }) =>
          quick_access_link && quick_access_label
      )
      .map(({ quick_access_link, quick_access_icon, quick_access_label }) => {
        return {
          icon: {
            ...imageAdapter(quick_access_icon),
            alt: quick_access_label
              ? i18n.t('global.icon', {
                  title: quick_access_label.toLowerCase(),
                })
              : null,
          },
          name: quick_access_label,
          link: $enhancedLinkSerializer(quick_access_link),
        };
      }),
  },
});

export const sanitizeItems = (
  moduleData,
  pageData,
  documentType,
  i18n,
  $enhancedLinkSerializer,
  ekomi = {}
) => {
  const { withEkomiRating, ekomiData, ekomiInfobulle, ekomiInfobulleWhite } =
    ekomi;

  return moduleData.map((item, index) => {
    let title = stripRichTextWrapperTag(item.hero_push_title);
    let subtitle = richText(item.hero_push_subtitle);

    if (index === 0 && pageData && documentType === 'product_page') {
      const { starting_price, price_frequency, legal_reference } = pageData;

      if (starting_price) {
        subtitle = `<p>${getPrice(
          starting_price,
          price_frequency,
          legal_reference,
          i18n
        )}</p>`;
      }
    }

    const imageMobile = imageAdapter(
      item.hero_push_mobile_image,
      item.hero_push_image_alt
    );

    const imageDesktop = imageAdapter(
      item.hero_push_desktop_image,
      item.hero_push_image_alt
    );

    const data = {
      title,
      subtitle,
      description: richText(item.hero_push_description),
      link: item.hero_push_link
        ? $enhancedLinkSerializer(item.hero_push_link)
        : null,
      linkLabel: item.hero_push_link_label,
      imageMobile: imageMobile?.filename ? imageMobile : null,
      imageDesktop: imageDesktop?.filename ? imageDesktop : null,
      rating:
        withEkomiRating && ekomiData
          ? {
              rating: ekomiData?.average_rating,
              ratingLabel: `<b>${getRating(
                ekomiData?.average_rating,
                i18n
              )}/5</b> (${ekomiData?.review_count} avis)`,
              infobulle: {
                description: ekomiInfobulle,
                white: ekomiInfobulleWhite,
              },
            }
          : null,
      gradientColor: item.hero_push_gradient,
    };

    return data;
  });
};

export default async (
  { primary, items },
  pageData,
  context,
  { documentType }
) => {
  const { $i18n, $enhancedLinkSerializer } = context;

  const ekomiAccount =
    pageData.ekomi_account ?? pageData.market?.data?.ekomi_account;

  const withEkomiRating = primary.with_ekomi_rating;
  let ekomiData, ekomiInfobulle, ekomiInfobulleWhite;

  if (withEkomiRating) {
    ekomiInfobulle = primary.ekomi_rating_infobulle;
    ekomiInfobulleWhite = primary.ekomi_rating_infobulle_white;
    ekomiData = await getEkomiData(ekomiAccount, 'reviews', context);
  }

  const heroData = sanitizeItems(
    items,
    pageData,
    documentType,
    $i18n,
    $enhancedLinkSerializer,
    { withEkomiRating, ekomiData, ekomiInfobulle, ekomiInfobulleWhite }
  )?.[0];

  if (!heroData) {
    return null;
  }

  const quickAccessData = getQuickAccessData(
    heroData,
    $enhancedLinkSerializer,
    $i18n
  );

  return {
    narrowHero: primary.narrow_hero,
    primaryColor: primary.primary_color,
    heroData,
    hasFixedButton: primary.fixed_button,
    blackColor: primary.text_color === 'black',
    ...quickAccessData,
  };
};
